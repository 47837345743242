import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableRichMenuTemplate() {
  const toast = useToast()
  const refRichMenuTemplateTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msg = {
    errorfetch: t2('Error fetching {module} list', { module: t('Rich Menu Template') }),
    name: `${t('Name')} » ${t('Type')} » ${t('Template')} » ${t('ID')}`,
    detail: `${t('Chat Bar Title')} » ${t('Display Menu by Default')} » ${t('Template')}`,
    actions: `${t('Actions')}`,
  }

  // Table Handlers
  const richMenuTemplateColumns = [
    {
      key: 'name',
      sortable: false,
      label: msg.name,
    },
    { key: 'detail', sortable: false, label: msg.detail },
    { key: 'actions', sortable: false, label: msg.actions },
  ]

  const richMenuTemplateColumnStyle = key => {
    if (key === 'name') return 'width: 40%'
    if (key === 'detail') return 'width: 40%'
    if (key === 'actions') return 'width: 20%'
    return ''
  }

  const richMenuTemplatePerPage = ref(5)
  const richMenuTemplateTotal = ref(0)
  const richMenuTemplateCurrentPage = ref(1)
  const richMenuTemplatePerPageOptions = [2, 5, 10, 25, 50, 100]
  const richMenuTemplateSearchQuery = ref('')
  const richMenuTemplateSortBy = ref('name')
  const richMenuTemplateIsSortDirDesc = ref(true)
  const richMenuTemplateLocale = ref('th')

  const richMenuTemplateDataMeta = computed(() => {
    const localItemsCount = refRichMenuTemplateTable.value ? refRichMenuTemplateTable.value.localItems.length : 0
    return {
      from: richMenuTemplatePerPage.value * (richMenuTemplateCurrentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: richMenuTemplatePerPage.value * (richMenuTemplateCurrentPage.value - 1) + localItemsCount,
      of: richMenuTemplateTotal.value,
    }
  })

  const refetchRichMenuTemplateData = () => {
    refRichMenuTemplateTable.value.refresh()
  }

  watch([richMenuTemplateCurrentPage, richMenuTemplatePerPage, richMenuTemplateSearchQuery, richMenuTemplateLocale], () => {
    refetchRichMenuTemplateData()
  })

  const getListRichMenuTemplate = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: richMenuTemplateSearchQuery.value,
      perPage: richMenuTemplatePerPage.value,
      page: richMenuTemplateCurrentPage.value,
      sortBy: richMenuTemplateSortBy.value,
      sortDesc: richMenuTemplateIsSortDirDesc.value,
      language: richMenuTemplateLocale.value,
      lineOaId: id,
    }
    store
      .dispatch('richmenu-template-store/listRichMenuTemplate', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        richMenuTemplateTotal.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-rich-menu-template`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msg.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  return {
    getListRichMenuTemplate,
    richMenuTemplateColumns,
    richMenuTemplateColumnStyle,
    richMenuTemplatePerPage,
    richMenuTemplateCurrentPage,
    richMenuTemplateTotal,
    richMenuTemplateDataMeta,
    richMenuTemplatePerPageOptions,
    richMenuTemplateSearchQuery,
    richMenuTemplateSortBy,
    richMenuTemplateIsSortDirDesc,
    richMenuTemplateLocale,

    refRichMenuTemplateTable,
    refetchRichMenuTemplateData,
  }
}
