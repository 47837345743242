<template>
  <div>
    <!-- Richmenu Template -->
    <b-card
      border-variant="danger"
      class="p-0 m-0"
    >
      <b-card-title class="d-flex mb-0">
        <b-alert
          show
          variant="danger"
          class="w-100"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="LayoutIcon"
            />
            <span class="ml-25">{{ $t('Rich Menu Template') }}</span>
          </div>
        </b-alert>
        <div
          class="ml-1 cursor-pointer"
          @click="toggleTemplate = !toggleTemplate"
        >
          <feather-icon
            :icon="(toggleTemplate === false)? `ChevronDownIcon` : `ChevronUpIcon`"
            size="16"
            style="margin-top: 1rem;"
          />
        </div>
      </b-card-title>

      <div v-if="toggleTemplate === false">
        <!-- Table Top -->
        <div class="m-0 mb-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="4"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="richMenuTemplatePerPage"
                    :options="richMenuTemplatePerPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- Search -->
            <b-col
              cols="8"
            >
              <b-row>
                <b-col
                  cols="7"
                  class="p-0 m-0"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-if="$can('create', 'RichMenu')"
                      variant="primary"
                      @click="addRichMenuTemplate"
                    >
                      {{ `${$t('Add')} ${$t('Rich Menu Template')}` }}
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                      v-model="richMenuTemplateSearchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <!-- Table -->
        <b-table
          ref="refRichMenuTemplateTable"
          :items="getListRichMenuTemplate"
          responsive
          :fields="richMenuTemplateColumns"
          primary-key="id"
          :sort-by.sync="richMenuTemplateSortBy"
          show-empty
          hover
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="richMenuTemplateIsSortDirDesc"
          class="position-relative"
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="richMenuTemplateColumnStyle(field.key)"
            >
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="TagIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.name }}</span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="InfoIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ $t(`RichMenuType.${textFirstUpper(data.item.type)}`) }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="LayoutIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-img
                      class="p-0 m-0"
                      style="margin: 0.4rem !important;"
                      thumbnail
                      :src="require(`@/assets/images/template_richmenu/${data.item.layout_name}.png`)"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-img
                      class="p-0 m-0"
                      style="margin: 0.4rem !important;"
                      thumbnail
                      :src="data.item.image_url"
                      width="180"
                    />
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="HashIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `info` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Detail -->
          <template #cell(detail)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="MessageSquareIcon"
                      size="14"
                    />
                    {{ $t('Chat Bar Title') }}
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-success` : `success` "
                      class="text-capitalize"
                    >
                      {{ data.item.chat_bar_title }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="MenuIcon"
                      size="14"
                    />
                    {{ $t('Display Menu by Default') }}
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-success` : `success` "
                      class="text-capitalize"
                    >
                      {{ isDisplayMenu(data.item.display_menu) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
              <b-list-group style="margin-top: 0.3rem; margin-bottom: 0px !important; padding-bottom: 0px !important;">
                <b-list-group-item
                  style="padding: 0.2rem 0rem 0.2rem 0rem;"
                >
                  <b-card-actions
                    :title="$t('Rich Menu Template')"
                    action-collapse
                    :collapsed="true"
                  >
                    <b-card-body
                      class="p-0"
                      style="margin-bottom: 0px !important; padding-bottom: 0px !important;"
                    >
                      <b-card
                        v-for="(dt, index) in data.item.detail"
                        :key="index"
                        no-body
                        style="border: 1px solid #eeeeee; margin: 0.4rem 0rem 0rem 0rem; padding: 0rem 0rem 0rem 0rem;"
                      >
                        <div
                          class="card-header"
                        >
                          <!-- Title -->
                          <div>
                            <b-card-title>{{ dt.title }}</b-card-title>
                          </div>
                        </div>
                        <b-card-body>
                          <b-row>
                            <!-- Type -->
                            <b-col
                              cols="12"
                              class="mb-md-0 mb-2"
                            >
                              {{ typeToLabel(dt.type) }}
                            </b-col>
                          </b-row>
                          <b-row v-if="dt.type === 'text'">
                            <!-- Text -->
                            <b-col
                              v-if="dt.value.text"
                              cols="12"
                              class="mb-md-0 mb-2 mt-1"
                            >
                              <b-form-group
                                :label="$t('Enter any chat keywords or other text here (50 characters or less)')"
                                :label-for="`text-text-${index}`"
                              >
                                <b-form-textarea
                                  :id="`text-text-${index}`"
                                  :value="dt.value.text"
                                  :placeholder="$t('Enter Text')"
                                  rows="3"
                                  readonly
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row v-else-if="dt.type === 'link'">
                            <!-- URL,Label -->
                            <b-col
                              cols="12"
                              class="mb-md-0 mb-2"
                            >
                              <b-row>
                                <!-- URL -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2 mt-1"
                                >
                                  <b-form-group
                                    :label="$t('URL')"
                                    :label-for="`link-label-${index}`"
                                  >
                                    <b-form-input
                                      :id="`link-label-${index}`"
                                      :value="dt.value.url"
                                      :placeholder="$t('URL')"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <!-- Label -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2"
                                >
                                  <b-form-group
                                    :label="$t('Action label (20 characters or less)')"
                                    :label-for="`link-label-${index}`"
                                  >
                                    <b-form-textarea
                                      :id="`link-label-${index}`"
                                      :value="dt.value.label"
                                      :placeholder="$t('Enter a description of this action')"
                                      rows="3"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row v-else-if="dt.type === 'liff'">
                            <!-- URL,Label -->
                            <b-col
                              cols="12"
                              class="mb-md-0 mb-2"
                            >
                              <b-row>
                                <!-- URL -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2 mt-1"
                                >
                                  <b-form-group
                                    :label="$t('URL')"
                                    :label-for="`link-label-${index}`"
                                  >
                                    <b-form-input
                                      :id="`link-label-${index}`"
                                      :value="dt.value.url"
                                      :placeholder="$t('URL')"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <!-- Label -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2"
                                >
                                  <b-form-group
                                    :label="$t('Action label (20 characters or less)')"
                                    :label-for="`link-label-${index}`"
                                  >
                                    <b-form-textarea
                                      :id="`link-label-${index}`"
                                      :value="dt.value.label"
                                      :placeholder="$t('Enter a description of this action')"
                                      rows="3"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row v-else-if="dt.type === 'postback'">
                            <!-- Text,Postback -->
                            <b-col
                              cols="12"
                              class="mb-md-0 mb-2"
                            >
                              <b-row>
                                <!-- Text -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2"
                                >
                                  <b-form-group
                                    :label="$t('Action text (20 characters or less)')"
                                    :label-for="`postback-text-${index}`"
                                  >
                                    <b-form-textarea
                                      :id="`postback-text-${index}`"
                                      :value="dt.value.text"
                                      :placeholder="$t('Enter a description of this action')"
                                      rows="3"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <!-- Postback Data -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2 mt-1"
                                >
                                  <b-form-group
                                    :label="$t('Postback Data')"
                                    :label-for="`postback-data-${index}`"
                                  >
                                    <b-form-input
                                      :id="`postback-data-${index}`"
                                      :value="dt.value.data"
                                      :placeholder="$t('Postback Data')"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row v-else-if="dt.type === 'dialogue'">
                            <!-- Text,Postback -->
                            <b-col
                              cols="12"
                              class="mb-md-0 mb-2"
                            >
                              <b-row>
                                <!-- Text -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2"
                                >
                                  <b-form-group
                                    :label="$t('Action text (20 characters or less)')"
                                    :label-for="`dialogue-text-${index}`"
                                  >
                                    <b-form-textarea
                                      :id="`dialogue-text-${index}`"
                                      :value="dt.value.text"
                                      :placeholder="$t('Enter a description of this action')"
                                      rows="3"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <!-- Dialogue Name -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2 mt-1"
                                >
                                  <b-form-group
                                    :label="$t('Postback Data')"
                                    :label-for="`dialogue-name-${index}`"
                                  >
                                    <b-form-input
                                      :id="`dialogue-name-${index}`"
                                      :value="dt.value.name"
                                      :placeholder="$t('Dialogue')"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <!-- Postback Data -->
                                <b-col
                                  cols="12"
                                  class="mb-md-0 mb-2 mt-1"
                                >
                                  <b-form-group
                                    :label="$t('Postback Data')"
                                    :label-for="`dialogue-postback-data-${index}`"
                                  >
                                    <b-form-input
                                      :id="`dialogue-postback-data-${index}`"
                                      :value="dt.value.data"
                                      :placeholder="$t('Postback Data')"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-card-body>

                      </b-card>
                    </b-card-body>
                  </b-card-actions>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div style="margin-top: 0.3rem;">
              <b-button
                size="sm"
                variant="info"
                style="margin-left: 0.5rem;"
                @click.stop="createRichMenu(data.item)"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  size="14"
                  style="margin-right: 0.4rem;"
                />
                <span class="align-middle">{{ `${$t('Create')} ${$t('Rich Menu')}` }}</span>
              </b-button>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-button
                size="sm"
                variant="warning"
                style="margin-left: 0.5rem;"
                @click.stop="editRichMenuTemplate(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="14"
                  style="margin-right: 0.4rem;"
                />
                <span class="align-middle">{{ `${$t('Edit')} ${$t('Rich Menu')}` }}</span>
              </b-button>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-button
                size="sm"
                variant="outline-danger"
                style="margin-left: 0.5rem;"
                @click.stop="deleteRichMenuTemplate(data.item)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="14"
                  style="margin-right: 0.4rem;"
                />
                <span class="align-middle">{{ `${$t('Delete')} ${$t('Rich Menu Template')}` }}</span>
              </b-button>
            </div>
          </template>

        </b-table>

        <!-- Table Footer -->
        <div class="mx-0 mb-0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: richMenuTemplateDataMeta.from, to: richMenuTemplateDataMeta.to, of: richMenuTemplateDataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="richMenuTemplateCurrentPage"
                :total-rows="richMenuTemplateTotal"
                :per-page="richMenuTemplatePerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-overlay
        :show="confirmRichMenuTemplate.busy"
        no-wrap
        @shown="onShownRichMenuTemplate"
        @hidden="onHiddenRichMenuTemplate"
      >
        <template #overlay>
          <div
            v-if="confirmRichMenuTemplate.processing"
            class="text-center p-2 bg-primary text-light rounded"
          >
            <feather-icon
              icon="UploadCloudIcon"
              size="20"
            />
            <div class="mb-2">
              {{ `${$t('Processing')}...` }}
            </div>
            <b-progress
              min="1"
              max="10"
              :value="confirmRichMenuTemplate.counter"
              variant="success"
              height="6px"
              class="mx-n1"
              animated
            />
          </div>
          <div
            v-else
            ref="dialogRichMenuTemplate"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <b-card-text
              class="font-weight-bolder"
            >
              {{ (confirmRichMenuTemplate.title != '')? confirmRichMenuTemplate.title : $t('Are you sure?') }}
            </b-card-text>
            <b-card-text
              v-if="confirmRichMenuTemplate.text"
              class="font-weight-bolder"
            >
              {{ confirmRichMenuTemplate.text }}
            </b-card-text>
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="ml-3 mr-3"
                @click="onCancelRichMenuTemplate"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                @click="onOKRichMenuTemplate"
              >
                {{ $t('Ok') }}
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>

    <!-- Richmenu -->
    <b-card
      border-variant="success"
      class="p-0 m-0"
      style="margin-top: 0.8rem !important;"
    >
      <b-card-title class="d-flex mb-0">
        <b-alert
          show
          variant="success"
          class="w-100"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="GridIcon"
            />
            <span class="ml-25">{{ $t('Rich Menu') }}</span>
          </div>
        </b-alert>
        <div
          class="ml-1 cursor-pointer"
          @click="toggleRichMenu = !toggleRichMenu"
        >
          <feather-icon
            :icon="(toggleRichMenu === false)? `ChevronDownIcon` : `ChevronUpIcon`"
            size="16"
            style="margin-top: 1rem;"
          />
        </div>
      </b-card-title>

      <div v-if="toggleRichMenu === false">
        <!-- Table Top -->
        <div class="m-0 mb-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="4"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="richMenuPerPage"
                    :options="richMenuPerPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- Search -->
            <b-col
              cols="8"
            >
              <b-row>
                <b-col cols="7" />
                <b-col cols="5">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                      v-model="richMenuSearchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <!-- Table -->
        <b-table
          ref="refRichMenuTable"
          :items="getListRichMenu"
          responsive
          :fields="richMenuColumns"
          primary-key="id"
          :sort-by.sync="richMenuSortBy"
          show-empty
          hover
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="richMenuIsSortDirDesc"
          class="position-relative"
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="richMenuColumnStyle(field.key)"
            >
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="TagIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.name }}</span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="InfoIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ $t(`RichMenuType.${textFirstUpper(data.item.type)}`) }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="HashIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      v-if="data.item.type !== 'not_link'"
                      pill
                      :variant="(skin === 'dark')? `light-success` : `success` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                    <b-badge
                      v-else
                      pill
                      :variant="(skin === 'dark')? `light-secondary` : `secondary` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Use -->
          <template #cell(use)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="UsersIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-success` : `success` "
                      class="text-capitalize"
                    >
                      {{ data.item.use }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.date_show }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.time_show }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div
              style="margin-top: 0.3rem;"
            >
              <b-button
                size="sm"
                variant="success"
                style="margin-left: 0.5rem;"
                @click.stop="linkRichMenu(data.item)"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  size="14"
                  style="margin-right: 0.4rem;"
                />
                <span class="align-middle">{{ `${$t('Link')} ${$t('Rich Menu')}` }}</span>
              </b-button>
            </div>
            <div
              style="margin-top: 0.3rem;"
            >
              <b-button
                size="sm"
                variant="outline-danger"
                style="margin-left: 0.5rem;"
                @click.stop="deleteRichMenu(data.item)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="14"
                  style="margin-right: 0.4rem;"
                />
                <span class="align-middle">{{ `${$t('Delete')} ${$t('Rich Menu')}` }}</span>
              </b-button>
            </div>

            <div
              v-if="data.item.type !== 'not_link'"
              style="margin-top: 0.3rem;"
            >
              <span style="font-size: 14px;">
                <b-alert
                  show
                  variant="success"
                >
                  <div class="alert-body">
                    <feather-icon
                      class="mr-25"
                      icon="StarIcon"
                    />
                    <span class="ml-25">{{ $t('Linked with User') }}</span>
                  </div>
                </b-alert>
              </span>
            </div>

            <div
              v-if="data.item.is_default === '0'"
              style="margin-top: 0.3rem;"
            >
              <b-button
                size="sm"
                variant="info"
                style="margin-left: 0.5rem;"
                @click.stop="defaultRichMenu(data.item)"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="14"
                  style="margin-right: 0.4rem;"
                />
                <span class="align-middle">{{ `${$t('Set as default rich menu')}` }}</span>
              </b-button>
            </div>
            <div
              v-if="data.item.is_default === '1'"
              style="margin-top: 0.3rem;"
            >
              <span style="font-size: 14px;">
                <b-alert
                  show
                  variant="info"
                >
                  <div class="alert-body">
                    <feather-icon
                      class="mr-25"
                      icon="CheckCircleIcon"
                    />
                    <span class="ml-25">{{ `${$t('Rich menu is default')}` }}</span>
                  </div>
                </b-alert>
              </span>
            </div>
          </template>

        </b-table>

        <!-- Table Footer -->
        <div class="mx-0 mb-0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: richMenuDataMeta.from, to: richMenuDataMeta.to, of: richMenuDataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="richMenuCurrentPage"
                :total-rows="richMenuTotal"
                :per-page="richMenuPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BPagination,
  BFormInput,
  BAlert,
  BTable,
  BBadge,
  BListGroup,
  BListGroupItem,
  BButton,
  BImg,
  BFormGroup,
  BFormTextarea,
  BOverlay,
  BCardText,
  BProgress,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import tableRichMenuTemplate from './tableRichMenuTemplate'
import tableRichMenu from './tableRichMenu'
import richMenuStoreModule from './richMenuStoreModule'
import richMenuTemplateStoreModule from './richMenuTemplateStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BPagination,
    BFormInput,
    BAlert,
    BTable,
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton,
    BImg,
    BFormGroup,
    BFormTextarea,
    BOverlay,
    BCardText,
    BProgress,

    vSelect,
    BCardActions,
    FeatherIcon,
  },
  data() {
    return {
      skin: store.state.appConfig.layout.skin,
      confirmRichMenuTemplate: {
        action: '',
        title: '',
        text: '',
        busy: false,
        processing: false,
        counter: 1,
        id: 0,
      },
      toggleTemplate: false,
      toggleRichMenu: false,
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  methods: {
    isStateVariant(status) {
      if (status === 'wait') return 'danger'
      if (status === 'done') return 'success'
      return 'secondary'
    },
    isDisplayMenu(value) {
      if (value === 1 || value === true) return this.$i18n.t('Shown')
      if (value === 0 || value === false) return this.$i18n.t('Collapsed')
      return this.$i18n.t('Collapsed')
    },
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    typeToLabel(type) {
      if (type === 'text') return this.$i18n.t('Text')
      if (type === 'link') return this.$i18n.t('Link')
      if (type === 'liff') return this.$i18n.t('Liff')
      if (type === 'postback') return this.$i18n.t('Postback')
      if (type === 'dialogue') return this.$i18n.t('Dialogue')
      return this.$i18n.t('Not Set')
    },
    addRichMenuTemplate() {
      this.$router.push({ name: 'lineapi-richmenu-template-add' })
    },
    createRichMenu(item) {
      this.confirmRichMenuTemplate.action = 'create'
      this.confirmRichMenuTemplate.title = this.$i18n.t('Are you sure?')
      this.confirmRichMenuTemplate.text = `${this.$i18n.t('Create from a rich menu template')} » ${item.name}`
      this.confirmRichMenuTemplate.busy = true
      this.confirmRichMenuTemplate.processing = false
      this.confirmRichMenuTemplate.id = item.id
      this.confirmRichMenuTemplate.counter = 1
      // this.$swal({
      //   title: this.$i18n.t('Are you sure?'),
      //   text: `${this.$i18n.t('Create from a rich menu template')} » ${item.name}`,
      //   icon: 'info',
      //   showCancelButton: true,
      //   confirmButtonText: this.$i18n.t('Yes, create it'),
      //   cancelButtonText: this.$i18n.t('Cancel'),
      //   customClass: {
      //     confirmButton: 'btn btn-info',
      //     cancelButton: 'btn btn-outline-secondary ml-1',
      //   },
      //   buttonsStyling: false,
      // }).then(result => {
      //   if (result.value === true) {
      //     store.dispatch('richmenu-store/createRichMenu', item.id)
      //       .then(() => {
      //         const okMsg = this.$i18n.t('Process on successfully')
      //         this.$toast({
      //           component: ToastificationContent,
      //           props: {
      //             title: okMsg,
      //             icon: 'CheckCircleIcon',
      //             variant: 'success',
      //           },
      //         },
      //         {
      //           position: 'bottom-right',
      //         })
      //         this.$swal({
      //           icon: 'success',
      //           title: this.$i18n.t('Created'),
      //           text: this.$i18n.t('Your data has been created'),
      //           timer: 3000,
      //           customClass: {
      //             confirmButton: 'btn btn-success',
      //           },
      //         })
      //         this.refetchRichMenuTemplateData()
      //         this.refetchRichMenuData()
      //       })
      //       .catch(error => {
      //         const { response, message } = error
      //         if (response) {
      //           let errorMsg = this.$i18n.t(response?.data.error)
      //           if (errorMsg === '') {
      //             errorMsg = this.$i18n.t(response?.data.message)
      //           }
      //           this.$toast({
      //             component: ToastificationContent,
      //             props: {
      //               title: errorMsg,
      //               icon: 'AlertTriangleIcon',
      //               variant: 'danger',
      //             },
      //           },
      //           {
      //             position: 'bottom-right',
      //           })
      //         } else if (message) {
      //           this.$toast({
      //             component: ToastificationContent,
      //             props: {
      //               title: error?.message,
      //               icon: 'AlertTriangleIcon',
      //               variant: 'danger',
      //             },
      //           },
      //           {
      //             position: 'bottom-right',
      //           })
      //         }
      //       })
      //   }
      // })
    },
    editRichMenuTemplate(item) {
      this.$router.push({ name: 'lineapi-richmenu-template-edit', params: { id: item.id } })
    },
    deleteRichMenuTemplate(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('richmenu-template-store/deleteRichMenuTemplate', item.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchRichMenuTemplateData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    linkRichMenu(item) {
      this.$router.push({ name: 'lineapi-richmenu-link', params: { id: item.id } })
    },
    deleteRichMenu(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('richmenu-store/deleteRichMenu',
            {
              id: item.id,
              data:
              {
                lineOaId: this.lineOaId,
              },
            })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchRichMenuData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    onShownRichMenuTemplate() {
      // Focus the dialog prompt
      this.$refs.dialogRichMenuTemplate.focus()
    },
    onHiddenRichMenuTemplate() {
    },
    onCancelRichMenuTemplate() {
      this.confirmRichMenuTemplate.busy = false
    },
    onOKRichMenuTemplate() {
      this.confirmRichMenuTemplate.processing = true
      this.confirmRichMenuTemplate.counter = 5
      if (this.confirmRichMenuTemplate.action === 'create') {
        store.dispatch('richmenu-store/createRichMenu',
          {
            id: this.confirmRichMenuTemplate.id,
            data:
            {
              lineOaId: this.lineOaId,
            },
          })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.confirmRichMenuTemplate.busy = false
            this.confirmRichMenuTemplate.processing = false
            this.confirmRichMenuTemplate.counter = 10

            this.refetchRichMenuTemplateData()
            this.refetchRichMenuData()

            this.$swal({
              icon: 'success',
              title: this.$i18n.t('Added'),
              text: this.$i18n.t('Your data has been added'),
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.confirmRichMenuTemplate.busy = false
            this.confirmRichMenuTemplate.processing = false
          })
      }
    },
    defaultRichMenu(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t('You request to set rich menu as default.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, set it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('richmenu-store/defaultRichMenu',
            {
              id: item.id,
              data:
              {
                lineOaId: this.lineOaId,
              },
            })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Default'),
                text: this.$i18n.t('Your data has been default'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchRichMenuData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
  },
  setup() {
    if (!store.hasModule('richmenu-template-store')) store.registerModule('richmenu-template-store', richMenuTemplateStoreModule)
    onUnmounted(() => {
      if (store.hasModule('richmenu-template-store')) store.unregisterModule('richmenu-template-store')
    })
    if (!store.hasModule('richmenu-store')) store.registerModule('richmenu-store', richMenuStoreModule)
    onUnmounted(() => {
      if (store.hasModule('richmenu-store')) store.unregisterModule('richmenu-store')
    })

    const {
      getListRichMenuTemplate,
      richMenuTemplateColumns,
      richMenuTemplateColumnStyle,
      richMenuTemplatePerPage,
      richMenuTemplateCurrentPage,
      richMenuTemplateTotal,
      richMenuTemplateDataMeta,
      richMenuTemplatePerPageOptions,
      richMenuTemplateSearchQuery,
      richMenuTemplateSortBy,
      richMenuTemplateIsSortDirDesc,
      richMenuTemplateLocale,

      refRichMenuTemplateTable,
      refetchRichMenuTemplateData,

    } = tableRichMenuTemplate()

    const filterRichMenuTemplate = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-rich-menu-template`))
    if (filterRichMenuTemplate !== null) {
      richMenuTemplateSearchQuery.value = (filterRichMenuTemplate.searchQuery !== null) ? filterRichMenuTemplate.searchQuery : ''
      richMenuTemplatePerPage.value = (filterRichMenuTemplate.perPage !== null) ? filterRichMenuTemplate.perPage : ''
      richMenuTemplateCurrentPage.value = (filterRichMenuTemplate.page !== null) ? filterRichMenuTemplate.page : ''
      richMenuTemplateSortBy.value = (filterRichMenuTemplate.sortBy !== null) ? filterRichMenuTemplate.sortBy : ''
      richMenuTemplateIsSortDirDesc.value = (filterRichMenuTemplate.sortDesc !== null) ? filterRichMenuTemplate.sortDesc : ''
      richMenuTemplateLocale.value = (filterRichMenuTemplate.language !== null) ? filterRichMenuTemplate.language : ''
    }

    const {
      getListRichMenu,
      richMenuColumns,
      richMenuColumnStyle,
      richMenuPerPage,
      richMenuCurrentPage,
      richMenuTotal,
      richMenuDataMeta,
      richMenuPerPageOptions,
      richMenuSearchQuery,
      richMenuSortBy,
      richMenuIsSortDirDesc,
      richMenuLocale,

      refRichMenuTable,
      refetchRichMenuData,

    } = tableRichMenu()

    const filterRichMenu = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-rich-menu`))
    if (filterRichMenu !== null) {
      richMenuSearchQuery.value = (filterRichMenu.searchQuery !== null) ? filterRichMenu.searchQuery : ''
      richMenuPerPage.value = (filterRichMenu.perPage !== null) ? filterRichMenu.perPage : ''
      richMenuCurrentPage.value = (filterRichMenu.page !== null) ? filterRichMenu.page : ''
      richMenuSortBy.value = (filterRichMenu.sortBy !== null) ? filterRichMenu.sortBy : ''
      richMenuIsSortDirDesc.value = (filterRichMenu.sortDesc !== null) ? filterRichMenu.sortDesc : ''
      richMenuLocale.value = (filterRichMenu.language !== null) ? filterRichMenu.language : ''
    }

    return {
      getListRichMenuTemplate,
      richMenuTemplateColumns,
      richMenuTemplateColumnStyle,
      richMenuTemplatePerPage,
      richMenuTemplateCurrentPage,
      richMenuTemplateTotal,
      richMenuTemplateDataMeta,
      richMenuTemplatePerPageOptions,
      richMenuTemplateSearchQuery,
      richMenuTemplateSortBy,
      richMenuTemplateIsSortDirDesc,
      richMenuTemplateLocale,

      refRichMenuTemplateTable,
      refetchRichMenuTemplateData,

      getListRichMenu,
      richMenuColumns,
      richMenuColumnStyle,
      richMenuPerPage,
      richMenuCurrentPage,
      richMenuTotal,
      richMenuDataMeta,
      richMenuPerPageOptions,
      richMenuSearchQuery,
      richMenuSortBy,
      richMenuIsSortDirDesc,
      richMenuLocale,

      refRichMenuTable,
      refetchRichMenuData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
